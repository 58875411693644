import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    token: String
  }

  pay(event) {
    event.preventDefault()

    snap.pay(this.tokenValue, {
      onSuccess: function(result) {
        console.log(result)
      },
      onPending: function(result) {
        console.log(result)
      },
      onError: function(result) {
        console.log(result)
      }
    });
  }
}
