import { Controller } from "@hotwired/stimulus"
import autoComplete from "@tarekraafat/autocomplete.js"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
  static targets = ["input", "result"]
  static values = {
    autocompletePath: String
  }

  connect() {
    this.initAutocomplete();
    this.setupOutsideClickHandler();

    console.log(this.resultTarget.id)
  }

  initAutocomplete() {
    this.autoCompleteJS = new autoComplete({
      placeHolder: "Cari Produk",
      selector: () => {
        return this.inputTarget
      },
      wrapper: false,
      debounce: 300,
      data: {
        src: async (query) => {
          try {
            let url = `${this.autocompletePathValue}?query=${query}`
            const response = await fetch(url)
            const data = await response.json()

            return data.results
          } catch (error) {
            return []
          }
        },
        keys: ["caption"]
      },
      resultsList: {
        class: "tw-absolute tw-top-full tw-left-0 tw-w-full tw-bg-white tw-border tw-shadow-lg tw-z-50 tw-rounded-lg tw-overflow-hidden",
        noResults: true,
        position: "afterbegin",
        destination: `#${this.resultTarget.id}`,
        maxResults: 5,
        element: (list, data) => {
          if (data.results.length === 0) {
            const info = document.createElement("p");
            info.classList.add("tw-p-4", "tw-text-gray-700");
            info.innerHTML = `Tidak ada hasil untuk <strong>"${data.query}"</strong>`;
            list.prepend(info);
          }
        }
      },
      resultItem: {
        class: "tw-flex tw-items-start tw-p-3 tw-border-b tw-cursor-pointer tw-bg-white hover:tw-bg-gray-100",
        highlight: true,
        element: (element, data) => {
          const { brand_name, name, image_url, price, net_price, on_discount } = data.value;

          const priceHTML = on_discount
            ? `<div class="tw-text-gray-500 tw-text-xs"><del>${price}</del></div>
                <div class="tw-text-red-600 tw-font-semibold">${net_price}</div>`
            : `<div class="tw-text-gray-700 tw-font-semibold">${net_price}</div>`;

          element.innerHTML = `
            <div class="tw-w-16 md:tw-w-20 tw-mr-2 tw-flex-shrink-0">
              <img src="${image_url}" alt="${name}" class="tw-w-full tw-h-full tw-object-cover tw-rounded">
            </div>
            <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-y-1">
              <div class="tw-text-gray-400 tw-text-sm">${brand_name}</div>
              <div class="tw-font-medium tw-text-gray-700 tw-text-sm">${name}</div>
              <div class="tw-flex tw-items-center tw-space-x-2">${priceHTML}</div>
            </div>
          `;
        }
      },
      events: {
        input: {
          selection: (event) => {
            const url = event.detail.selection.value.path;
            Turbo.visit(url);
          },
          focus: () => {
            this.showResults();
          }
        }
      }
    });
  }

  showResults() {
    if (this.resultTarget) {
      this.autoCompleteJS.open();
      this.resultTarget.classList.remove("tw-hidden");
      this.resultTarget.classList.add("tw-block");
    }
  }

  hideResults() {
    if (this.resultTarget) {
      this.resultTarget.classList.add("tw-hidden");
      this.resultTarget.classList.remove("tw-block");
      this.autoCompleteJS.close();
    }
  }

  setupOutsideClickHandler() {
    document.addEventListener("click", (event) => {
      if (!this.element.contains(event.target)) {
        this.hideResults();
      }
    });
  }
}
